import cmos_design from "../../components/ProgramCatalog/assets/cmos_design.webp";
import QuatumImage from "../../components/CoursePage/assets/quantum_lab.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import cas_ceda from "../../components/CoursePage/assets/cas_ceda_logo.png";
import ieeeImage from "../../components/CoursePage/assets/ieee.webp";
import devnith from "../../components/CoursePage/assets/instructor-img/Devnith.webp";
import kanchana from "../../components/CoursePage/assets/instructor-img/Kanchana_Ranasinghe.webp";
import afham from "../../components/CoursePage/assets/instructor-img/Afham.webp";
import Abarajithan from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import sadeep from "../../components/CoursePage/assets/instructor-img/Sadeep Jayasumana.webp";
import ranga from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import kithmin from "../../components/CoursePage/assets/instructor-img/Kithmin.jpg";
import udara from "../../components/CoursePage/assets/instructor-img/Udara_Mendis.jpg";
import hirusha from "../../components/CoursePage/assets/instructor-img/Hirusha_Madhuwantha.jpg";
import sajith from "../../components/CoursePage/assets/instructor-img/Sajitha Madugalle.jpeg";
import imasha from "../../components/CoursePage/assets/instructor-img/Imasha_Nethmal.png";
import lohan from "../../components/CoursePage/assets/instructor-img/Lohan_Atapattu.jpg";
import shan from "../../components/CoursePage/assets/instructor-img/Shan_Jayasinghe.jpeg";
import achintha from "../../components/CoursePage/assets/instructor-img/achintha.png";
import nilan from "../../components/CoursePage/assets/instructor-img/Nilan_Udayanga.png";
import subodha from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import subramaniyam from "../../components/CoursePage/assets/instructor-img/Subramaniam_Thayaparan.jpeg";
import cmosICDesignFlayer from "../../components/CoursePage/assets/analog_cmos_ic_design.webp";
import { ECourseStatus, ICourse } from "../utils/utils";

export const analogCmosICDesign: ICourse = {
  header: {
    courseImage: cmos_design,
    programCategory: "Workshop",
    difficultyLevel: "Beginner",
    courseTitle: "The Art of Analog CMOS IC Design",
    specialization: "Free Workshop",
    courseDescription:
      "Dive into the exciting world of Analog CMOS IC Design in this one-day workshop, designed to equip you with the fundamentals of IC design, simulation, and layout. With the growing demand for skilled analog designers in the semiconductor industry, this hands-on session will guide you through MOSFET fundamentals, circuit design using IHSP SG13G2 PDK using Docker and practical applications of IC design tools.Whether you're a student, researcher, or aspiring chip designer, this workshop will provide you with valuable insights and real-world skills to kickstart your journey.",
    registerButton: "Register Now",
    registerLink: "https://forms.gle/aNcqJJAcgKF9prGKA",
    secondaryName: "One Day Online Workshop",
  },
  headerSecondSection: {
    starCount: 0,
    reviewCount: 0,
    registrationDeadline: "4 May 2025",
    parameterOne: "Workshop Date:",
    parameterTwo: "4 May 2025",
    parameterThree: "New Workshop",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
      {
        collaborationLogo: cas_ceda,
        collaborationLogosName: "CAS Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "Session 1",
      sessionContent: [
        {
          heading: "Welcome",
          description: "",
          subContent: [],
        },
        {
          heading: "Keynote speech",
          description: "",
          subContent: [],
        },
        {
          heading:
            "The value of developing IC Design in Sri Lanka, its economic Impact and opportunities",
          description: "",
          subContent: [],
        },
        {
          heading:
            "The basic model of the MOSFET and how to build an inverter out of MOSFETs",
          description: "",
          subContent: [],
        },

        {
          heading:
            "How to set up OSIC tools and working with IHSP SG13G2 PDK using Docker",
          description: "",
          subContent: [],
        },

        {
          heading: "Design, simulate and layout an Inverter using OSIC tools",
          description: "",
          subContent: [],
        },
      ],
    },
    {
      session: "Session 2",
      sessionContent: [
        {
          heading:
            "CMOS Process and Technology - how do we manufacture micro-circuits on Silicon",
          description: "",
          subContent: [],
        },
        {
          heading:
            "What are oscillators and phase-locked loops (PLLs), and why do we need them?",
          description: "",
          subContent: [],
        },

        {
          heading: "How to design, simulate and layout a phase-locked loop",
          description: "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [],
  experts: [
    {
      image: kithmin,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc, Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: Abarajithan,
      name: "Mr. Abarajithan Gnaneswaran",
      designation:
        "PhD Student in Electrical and Computer Eng. , UC San Diego, USA",
    },
    {
      image: udara,
      name: "Ms. Udara Mendis",
      designation: "Double Degree Master's Student, TUHH, Germany",
    },
    {
      image: hirusha,
      name: "Mr. Hirusha Madhuwantha",
      designation: "3rd year Undergraduate, ENTC,  UoM",
    },
    {
      image: sajith,
      name: "Mr. Sajitha Madugalle",
      designation: "3rd year Undergraduate, ENTC,  UoM",
    },

    {
      image: imasha,
      name: "Mr. Imasha Nethmal",
      designation: "3rd year Undergraduate, ENTC,  UoM",
    },
    {
      image: lohan,
      name: "Mr. Lohan Atapattu",
      designation: "3rd year Undergraduate, ENTC,  UoM",
    },
    {
      image: shan,
      name: "Dr. Shan Jayasinghe",
      designation:
        "Lecturer, Department of Business and Law, Southampton Solent University",
    },
   

    {
      image: achintha,
      name: "Dr. Achintha Kondarage",
      designation: "Staff Program Manager, Synopsys Inc.",
    },
    {
      image: nilan,
      name: "Dr. Nilan Udayanga",
      designation: "PhD, Florida International University, USA",
    },
    {
      image: subodha,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
    {
      image: subramaniyam,
      name: "Dr. Subramaniam Thayaparan",
      designation: "HoD, ENTC, UoM",
    },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [],
  classSchedule: [],
  prerequisite:
    "Anyone willing to get started with  Analog CMOS IC Designs can join and no prior experience is required.",
  isCourseFlyer: true,
  flyer: {
    description:
      "Learn more about Analog CMOS IC Design workshop from the workshop announcement flyer",
    button: "View Flyer",
    image: cmosICDesignFlayer,
  },
  courseStatus: ECourseStatus.OLD,
  showTimer: false,
  isDifferentBullet: true,
  isResources: false,
  resourcesYoutubeLink: "https://www.youtube.com/@skillsurfedu",
  resourcesGitHubLink: "https://github.com/SkillSurf/introduction_genAI",
  timerDate: "2024-06-06 00:00",
  showSchedule: true,
};
