import { Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import ProgramCatalog from "../components/ProgramCatalog/ProgramCatalog";
import JoinTheTeam from "../components/JoinTheTeam/JoinTheTeam";
import PaymentGateway from "../components/PaymentGateway/PaymentGateway";
import PaymentDetails from "../components/PaymentDetails/PaymentDetails";
import ContactUs from "../components/ContactUs/ContactUs";
import PolicyPage from "../components/PolicyPage/PolicyPage";
import { refundPolicy } from "../shared/policy/refund-policy";
import { privacyPolicy } from "../shared/policy/privacy-policy";
import { termAndConditions } from "../shared/policy/terms-and-conditions";
import CoursePage from "../components/CoursePage/CoursePage";
import { microcontrollerBasedEmbeddedSystemDesign } from "../shared/courses/microcontroller-based-embedded-system-design";
import { embeddedMachineLearningForEdgeComputing } from "../shared/courses/embedded-machine-learning-for-edge-computing";
import { systemVerilogForAsicFpgaDesignAndSimulation } from "../shared/courses/system-verilog-for-asic-fpga-design-and-simulation";
import { introductionToQuantumComputing } from "../shared/courses/introduction-to-quantum-computing";
import { embeddedProductDesignForIot } from "../shared/courses/embedded-product-design-for-iot";
import { cryptographyFundamentalsSecuringTheDigitalWorld } from "../shared/courses/cryptography-fundamentals-securing-the-digital-world";
import { introductionToGenAi } from "../shared/courses/introduction-to-generative-ai";
import AboutUs from "../components/AboutUs/AboutUs";
import { fundamentalsOfDigitalSystemDesign } from "../shared/courses/fundamentals-of-digital-system-design";
import { analogCmosICDesign } from "../shared/courses/analog-cmos-ic_design";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/program-catalog" element={<ProgramCatalog />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/join-the-team" element={<JoinTheTeam />} />
      <Route path="/payments" element={<PaymentGateway />} />
      <Route path="/payment-details" element={<PaymentDetails />} />
      <Route
        path="/refund-policy"
        element={<PolicyPage policy={refundPolicy} />}
      />
      <Route path="/about-us" element={<AboutUs />} />
      <Route
        path="/privacy-policy"
        element={<PolicyPage policy={privacyPolicy} />}
      />
      <Route
        path="/terms-and-conditions"
        element={<PolicyPage policy={termAndConditions} />}
      />
      <Route
        path="/microcontroller-based-embedded-system-design"
        element={
          <CoursePage course={microcontrollerBasedEmbeddedSystemDesign} />
        }
      />
      <Route
        path="/embedded-machine-learning-for-edge-computing"
        element={
          <CoursePage course={embeddedMachineLearningForEdgeComputing} />
        }
      />
      <Route
        path="/verilog-for-ASIC-FPGA-design-simulation"
        element={
          <CoursePage course={systemVerilogForAsicFpgaDesignAndSimulation} />
        }
      />
      <Route
        path="/introduction-to-quantum-computing"
        element={<CoursePage course={introductionToQuantumComputing} />}
      />
      <Route
        path="/embedded-product-design"
        element={<CoursePage course={embeddedProductDesignForIot} />}
      />
      <Route
        path="/cryptography-fundamentals-securing-the-digital-world"
        element={
          <CoursePage
            course={cryptographyFundamentalsSecuringTheDigitalWorld}
          />
        }
      />

      <Route
        path="/introduction-to-generative-ai"
        element={<CoursePage course={introductionToGenAi} />}
      />

      <Route
        path="/fundamentals-of-digital-system-design"
        element={<CoursePage course={fundamentalsOfDigitalSystemDesign} />}
      />

<Route
        path="/analog-cmos-ic-design"
        element={<CoursePage course={analogCmosICDesign} />}
      />
    </Routes>
  );
};

export default AppRoutes;
